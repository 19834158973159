<template>
  <a-result
      status="success"
      title="Successfully Purchased Cloud Server ECS!"
      sub-title="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait.">
  </a-result>
</template>

<script>
export default {
  name: 'Exception404'
}
</script>

<style scoped>

</style>
